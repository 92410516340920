import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBriefcase,
  faImage,
  faTimes,
  faGear,
  faSackDollar,
  faInfo,
  faTableColumns,
} from "@fortawesome/free-solid-svg-icons";
import { Nav, Button, Form } from "react-bootstrap";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import appIcon from "../../assets/prototype.circle.png";

// import { faCabinetFiling } from "@fortawesome/pro-light-svg-icons";
// import { faGrid2, faShelves } from "@fortawesome/pro-regular-svg-icons";

function SideBar(props) {
  const location = useLocation();

  return (
    <div className={classNames("sidebar", { "is-open": props.isOpen })}>
      <div className="sidebar-header">
        <img src={appIcon} alt="Icon" style={{ height: "30px" }} />
        <h5 style={{ color: "#fff" }}>Prototype Assistant</h5>
      </div>

      <Nav className="flex-column pt-2">
        {/* <Form className="ml-3">
          <Form.Group
            controlId="search "
            style={{
              paddingLeft: "15px",
              paddingRight: "15px",
              paddingTop: "8px",
              paddingBottom: "8px",
            }}
          >
            <Form.Control type="text" placeholder="Search..." />
          </Form.Group>
        </Form> */}

        <Nav.Item>
          <Link
            style={{ textDecoration: "none", color: "white" }}
            to="/"
            className={classNames("nav-link", {
              active: location.pathname === "/",
            })}>
            <FontAwesomeIcon icon={faHome} className="mr-2" />
            <span>Home</span>
          </Link>
        </Nav.Item>

        <Nav.Item>
          <Link
            style={{ textDecoration: "none", color: "white" }}
            to="/settings"
            className={classNames("nav-link", {
              active: location.pathname === "/settings",
            })}>
            <FontAwesomeIcon icon={faGear} size="lg" className="mr-2" />
            Admin
          </Link>
        </Nav.Item>
      </Nav>
    </div>
  );
}

export default SideBar;
