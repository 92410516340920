import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  REFRESH_TOKEN,
  SET_CURRENT_USER,
} from "../constans/authConstants";

// const user = JSON.parse(localStorage.getItem("user"));
const isEmpty = require("is-empty");

// const initialState = user ? {isLoggedIn: true, user} : {isLoggedIn: false,user};

const initialState = {
  isAuthenticated: false,
  // isLoggedIn: false,
  user: {},
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
      };
    //   case USER_LOADING:
    //     return {
    //       ...state,
    //       loading: true,
    //     };
    default:
      return state;
  }
};

// export default function(state = initialState, action){
//     export const authReducer = (state = initialState, action) => {
//     const {type,payload} = action;

//     switch(type){
//         case LOGIN_SUCCESS:
//         return {
//             ...state,
//             isLoggedIn: true,
//             user: payload.user
//         }
//         case LOGIN_ERROR:
//         return{
//             ...state,
//             isLoggedIn: false,
//             user: null
//         }
//         case LOGOUT:
//         return{
//             ...state,
//             isLoggedIn: false,
//             user: null
//         }
//         case REFRESH_TOKEN:
//         return{
//             ...state,
//             user: {...user,accessToken: payload}
//         }

//         default:
//         return state;
//     }
// }
