import React from "react";
import { Modal, Button } from "react-bootstrap";

class CustomModal extends React.Component {
  render() {
    const { show, onHide, title, content, onSave, btnName } = this.props;

    return (
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={onHide}>
            Cancel
          </Button>
          <Button variant="outline-success" onClick={onSave}>
            {btnName}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  handleSave = () => {
    // Add your save logic here
    // This function will be called when the "Save" button is clicked
    // You can pass additional data or call a callback function if needed
  };
}

export default CustomModal;