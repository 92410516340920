import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import apiCalls from "../../services/apiCalls";
// import { useNavigate } from "react-router-dom";
import SelectProcess from "../SelectProcess/SelectProcess";

// Bootstrap
import { Card, Row, Col, Button } from "react-bootstrap";
//Images
import noImageIcon from "../../assets/no_image_available.jpeg";

// CSS
import "./Home.css";

function Home() {
  const navigate = useNavigate();
  const [cropList, setCropList] = useState([]);
  const [showChildComponent, setShowChildComponent] = useState(false);

  useEffect(() => {
    apiCalls
      .getCrops()
      .then((res) => {
        console.log(res.data);
        setCropList(res.data);
      })
      .catch((error) => {
        console.log("error not getting data: " + error);
      });
  }, []);

  const dataToPass = { name: "John Doe", age: 25 };

  return (
    <div className="home-container">
      <h1 style={{ padding: "13px" }}>All Crops</h1>
      {/* <button onClick={goToView}> Click Me </button> */}
      {/* {showChildComponent && <SelectProcess dataToPass={dataToPass} />} */}

      <Row xs={1} md={2} lg={5} className="g-4">
        {cropList.map((crop) => (
          <Col key={crop.id}>
            <Link
              to={`/process/crop/${crop.id}`}
              // to="/process"
              // to={{ pathname: "/process", state: dataToPass }}
              style={{ textDecoration: "none" }}
              className="link-info">
              <Card style={{ width: "18em" }}>
                <Card.Img
                  variant="top"
                  style={{ height: "200px" }}
                  src={
                    crop.picture !== null
                      ? apiCalls.baseURL + crop.picture
                      : noImageIcon
                  }
                />
                <Card.Body>
                  <Card.Title>{crop.name}</Card.Title>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default Home;
