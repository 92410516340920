import React, { Component } from "react";
import SideBar from "./pages/SideBarAndNavBar/Sidebar";
import Navbar from "./pages/SideBarAndNavBar/Navbar";
import Login from "./pages/Login/Login";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import setAuthToken from "./services/setAuthToken";
import store from "./redux/store";
import { setCurrentUser, logoutUser } from "./redux/actions/auth";
import { jwtDecode } from "jwt-decode";

//CSS
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
let jwt;

if (localStorage.getItem("jwtToken")) {
  setAuthToken(localStorage.getItem("jwtToken"));
  const getToken = localStorage.getItem("jwtToken");
  jwt = JSON.parse(getToken);
  const decoded = jwtDecode(jwt.refresh);
  store.dispatch(setCurrentUser(localStorage.getItem("jwtToken")));

  const timestamp = Math.floor(Date.now() / 1000); // Get the number of seconds
  const getTokenTime = decoded.exp - timestamp;

  if (getTokenTime <= 0) {
    console.log("Timer_Expired", getTokenTime);
    store.dispatch(logoutUser());
  }
}

class App extends Component {
  constructor(props) {
    super(props);

    // Mobile first
    this.state = {
      isOpen: false,
      isMobile: true,
    };
    this.previousWidth = -1;
  }

  updateWidth() {
    const width = window.innerWidth;
    const widthLimit = 576;
    const isMobile = width <= widthLimit;
    const wasMobile = this.previousWidth <= widthLimit;

    if (isMobile !== wasMobile) {
      this.setState({
        isOpen: !isMobile,
      });
    }

    this.previousWidth = width;
  }

  componentDidMount() {
    this.updateWidth();
    window.addEventListener("resize", this.updateWidth.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth.bind(this));
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    return (
      <div className="App wrapper">
        {this.props.auth.isAuthenticated ? (
          <>
            <SideBar toggle={this.toggle} isOpen={this.state.isOpen} />
            <Navbar toggle={this.toggle} isOpen={this.state.isOpen} />
          </>
        ) : (
          <Login />
        )}
      </div>
    );
  }
}

App.propTypes = {
  auth: PropTypes.object.isRequired,
};

// This is a function used to map the state from the Redux store to the props of the App component.
const mapStateToProps = (state) => ({
  auth: state.auth,
});

// This is exporting the App component after connecting it to the Redux store using the connect function.
export default connect(mapStateToProps, {})(App);

// export default App;
