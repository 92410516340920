import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";

import apiCalls from "../../services/apiCalls";
//Images
import noLineImage from "../../assets/no_image_available.jpeg";
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
// Bootstrap
import { Row, Col, Card, Modal } from "react-bootstrap";
// CSS
import "./FilteredPhenotypes.css";

const FilteredPhenotypes = (props) => {
  const location = useLocation();
  const phenotypes = location.state.filteredPhenotypes;
  const crop = location.state.cropData;
  //   console.log(phenotypes);

  const [show, setShow] = useState(false);
  const [filteredLines, setFilteredLines] = useState([]);
  const [filteredResults, setFilteredResults] = useState(filteredLines);
  const [ranks, setRanks] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSearch = (e) => {
    let value = e.target.value;
    let result = [];

    result = filteredLines.filter((data) => {
      return data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    });

    setFilteredResults(result);
  };

  useEffect(() => {
    async function getLines() {
      const linesArray = new Set(filteredLines);
      const response = await apiCalls.getLines(crop.id);
      const data = response.data;

      for (let i in phenotypes) {
        const filter = data.filter((item) => item.id === phenotypes[i].line);
        filter.forEach((item) => linesArray.add(item));
      }

      // const convertedObj = Array.from(linesArray).map((array) => array[0]);
      // console.log(Array.from(linesArray));
      setFilteredLines(Array.from(linesArray));
      setFilteredResults(Array.from(linesArray));
      //   sortAndRankPhenotypes();
    }

    getLines();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await apiCalls.getPhenoTypesByCrop(crop.id);
        const responseData = response.data;
        // setPhenotypes(responseData);
        sortAndRankPhenotypes(responseData);
      } catch (error) {
        console(error);
      }
    }
    fetchData();
  }, []);

  // need to get all pheno types
  // filter out any matched pheno's
  // and append into new array

  const sortAndRankPhenotypes = async (data) => {
    let sortedPheno = data
      .slice()
      .sort((a, b) => ((a.value_num ?? 0.0) > (b.value_num ?? 0.0) ? -1 : 1));

    console.log(sortedPheno);

    let dictionary = sortedPheno.reduce((acc, element) => {
      acc[element.trait] = acc[element.trait] || [];
      acc[element.trait].push(element);
      return acc;
    }, {});
    // console.log(dictionary);

    // Ranking Dictionary
    let index = 0;
    let prev = null;
    let currentRank = 0;
    let newRanks = new Set(ranks);

    for (let key in dictionary) {
      if (dictionary.hasOwnProperty(key)) {
        index = 0;
        dictionary[key].forEach((item) => {
          index += 1;
          if (prev === null || item.line !== prev) {
            prev = item.line;
            currentRank = index;
          }
          newRanks.add({
            rank: currentRank,
            lineValue: item.value_num ?? 0.0,
            lineName: item.line,
            traitName: item.trait,
          });
        });
      }
    }

    setRanks(Array.from(newRanks));

    // console.log("DictionarysetRankss", ranks);
  };

  console.log("filteredResultss", filteredResults);

  return (
    // Set to Horizontal
    <div className="findALine__container">
      {/* Filter Component */}
      {/* <FilterComponent /> */}
      {/* Lines Component */}
      <div className="findALine__data">
        <h1>{crop.name} Line Selection Lines</h1>
        {/* Show this when screen is on phone/Ipad  */}
        <div className="findALine__smallscreen__filter">
          <div className="findALine__smallscreen__filter_context">
            <FontAwesomeIcon icon={faFilter} onClick={handleShow} />
            <span onClick={handleShow}>Filters </span>
            <Modal show={show} onHide={handleClose} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Woohoo, you are reading this text in a modal!
              </Modal.Body>
            </Modal>
          </div>
        </div>

        {/* Search Bar */}
        <form class="search-form">
          <FontAwesomeIcon icon={faMagnifyingGlass} />
          <input
            type="text"
            placeholder="Search"
            class="search-input"
            onChange={(e) => handleSearch(e)}
          />
        </form>

        <Row xs={1} md={2} lg={3} className="g-4">
          {filteredResults.length !== 0 ? (
            filteredResults.map((line) => (
              <Col key={line.id}>
                <Link to="/linedetail" state={{ line, ranks }}>
                  <Card className="findALine__card">
                    <Card.Img
                      variant="top"
                      src={
                        line.picture !== null
                          ? apiCalls.baseURL + line.picture
                          : noLineImage
                      }
                      style={{ height: "400px" }}
                    />
                    <Card.Body>
                      <Card.Title
                        style={{ fontWeight: "bold", fontSize: "22px" }}>
                        {line.name}
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            ))
          ) : (
            <h2> No Lines Found</h2>
          )}
        </Row>
      </div>
    </div>
  );
};

export default FilteredPhenotypes;
