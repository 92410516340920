import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import apiCalls from "../../services/apiCalls";
import FindALineTable from "../FindALine/FindALineTable";

// Bootstrap
import { Row, Col } from "react-bootstrap";

// ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faSeedling,
  faArrowPointer,
} from "@fortawesome/free-solid-svg-icons";

// CSS
import "./SelectProcess.css";

// function SelectProcess(props) {
const SelectProcess = () => {
  const { id } = useParams();
  const [lines, setLines] = useState([]);
  const [crop, setCrop] = useState([]);

  useEffect(() => {
    apiCalls
      .getCrop(id)
      .then((res) => {
        setCrop(res.data);
      })
      .catch((err) => {
        console.log("Error getting crop data ", err);
      });
  }, []);

  useEffect(() => {
    apiCalls
      .getLines(id)
      .then((res) => {
        setLines(res.data);
      })
      .catch((err) => {
        console.log("Error getting Lines " + err);
      });
  }, []);

  // console.log(id);
  return (
    <div>
      {/* Header to navigate back to prev. screens */}
      {/* <h5 style={{ color: "green" }}>Home {" > "} Lettuce Details</h5> */}

      <h1 style={{ padding: "16px" }}>{crop.name} Details</h1>
      <Row xs={1} md={2} lg={4} className="g-4">
        <Col>
          <Link
            to="/findaline"
            state={{
              crop,
            }}
            style={{ textDecoration: "none", color: "black" }}>
            <div className="card-container">
              <h3>Find A Line</h3>
              {/* context and icon */}
              <div className="card-context">
                <h4>{lines.length} Lines</h4>
                <FontAwesomeIcon icon={faMagnifyingGlass} className="fa-2x" />
                {/* <FindALineTable products={products} /> */}
              </div>
            </div>
          </Link>
        </Col>

        <Col>
          <Link
            to="/traits"
            state={{ crop }}
            style={{ textDecoration: "none", color: "black" }}>
            <div className="card-container">
              <h3>Traits</h3>
              {/* context and icon */}
              <div className="card-context">
                <h4>View Traits</h4>
                <FontAwesomeIcon icon={faSeedling} className="fa-2x" />
              </div>
            </div>
          </Link>
        </Col>

        <Col>
          <Link
            to="/lineselection"
            state={{
              crop,
            }}
            style={{ textDecoration: "none", color: "black" }}>
            <div className="card-container">
              <h3>Line Selection</h3>
              {/* context and icon */}
              <div className="card-context">
                <h4>View Lines</h4>
                <FontAwesomeIcon icon={faArrowPointer} className="fa-2x" />
              </div>
            </div>
          </Link>
        </Col>

        <Col>
          <Link
            to="/testdetail"
            style={{ textDecoration: "none", color: "black" }}>
            <div className="card-container">
              <h3>Build A Line</h3>
              {/* context and icon */}
              <div className="card-context">
                <h4>View Lines</h4>
                <FontAwesomeIcon icon={faArrowPointer} className="fa-2x" />
              </div>
            </div>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default SelectProcess;
{
  /* <Card>
  <Card.Header>Find A Line</Card.Header>
  <Card.Body>
    <Card.Title>Special title treatment</Card.Title>
    <Card.Text>
      With supporting text below as a natural lead-in to additional content.
    </Card.Text>
  </Card.Body>
</Card>; */
}
