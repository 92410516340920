import React, { useState } from "react";
import cropImgOne from "../../assets/crop_img_one.jpeg";
import cropImgTwo from "../../assets/crop_img_two.jpeg";
import cropImgThree from "../../assets/crop_img_three.jpeg";

const MainImage = ({ mainImageUrl }) => {
  return (
    <div>
      <img src={mainImageUrl} alt="Main Product" />
    </div>
  );
};

const SubImages = ({ subImageUrls, activeIndex, onSubImageClick }) => {
  return (
    <div>
      {subImageUrls.map((url, index) => (
        <img
          key={index}
          src={url}
          alt={`Sub Image ${index + 1}`}
          onClick={() => onSubImageClick(index)}
          className={index === activeIndex ? "active" : ""}
        />
      ))}
    </div>
  );
};

const ProductPage = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const mainImageUrls = [cropImgOne];
  const subImageUrls = [cropImgOne, cropImgTwo, cropImgThree];

  const handleSubImageClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div>
      <MainImage mainImageUrl={subImageUrls[activeIndex]} />
      <SubImages
        subImageUrls={subImageUrls}
        activeIndex={activeIndex}
        onSubImageClick={handleSubImageClick}
      />
    </div>
  );
};

export default ProductPage;
