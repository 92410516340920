import React from "react";
import { Container } from "react-bootstrap";
import classNames from "classnames";
import NavBar from "../../content/Navbar";
import routes from "../../routers/Routes";
// import AppRoutes from "../Routers/Routes";
// import FlashMessage from "../Components /FlashMessage";

const Navbar = (props) => {
  return (
    <Container
      fluid
      className={classNames("content", { "is-open": props.isOpen })}>
      {/* <FlashMessage /> */}

      <NavBar toggle={props.toggle} />
      {routes}
    </Container>
  );
};

export default Navbar;
