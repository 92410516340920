import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home/Home";
import SelectProcess from "../pages/SelectProcess/SelectProcess";
import FindALine from "../pages/FindALine/FindALine";
import Traits from "../pages/Traits/Traits";
import LineSelection from "../pages/LineSelection/LineSelection";
import TraitChartModal from "../components/ui/TraitChartModal";
import LineDetail from "../pages/Details/LineDetails";
import ProductPage from "../pages/Details/TestDetail";
import FilteredPhenotypes from "../pages/FilteredPhenotypes/FilteredPhenotypes";

export default (
  <Routes>
    {/* User Routes */}
    {/* <Route path="/process" element={<SelectProcess />} /> */}

    <Route path="/traitchart/:id" element={<TraitChartModal />} />
    <Route path="/process/crop/:id" element={<SelectProcess />} />

    <Route path="/testdetail" element={<ProductPage />} />
    <Route path="/filteredphenotypes" element={<FilteredPhenotypes />} />
    <Route path="/linedetail" element={<LineDetail />} />
    <Route path="/lineselection" element={<LineSelection />} />
    <Route path="/traits" element={<Traits />} />
    <Route path="/findaline" element={<FindALine />} />
    <Route path="/" element={<Home />} />

    {/* Admin Routes */}
  </Routes>
);
