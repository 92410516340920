import React, { useEffect, useState } from "react";
import apiCalls from "../../services/apiCalls";
import { useParams, useLocation, Link } from "react-router-dom";

// Bootstrap
import { Row, Col, Alert, Button } from "react-bootstrap";
import Select from "react-select";

// Icons

// CSS
import "./LineSelection.css";

function LineSelection(props) {
  const location = useLocation();
  const cropData = location.state.crop;

  const [firstPickerPhenotypes, setFirstPickerPhenotypes] = useState([]);
  const [secondPickerPhenotypes, setSecondPickerPhenotypes] = useState([]);
  const [thirdPickerPhenotypes, setThirdPickerPhenotypes] = useState([]);

  const [traits, setTraits] = useState([]);
  const [lines, setLines] = useState([]);
  const [error, setError] = useState(null);
  // const [filteredPhenotypes, setFilteredPhenotypes] = useState([]);

  // State of Pickers
  const [firstSelectCompleted, setFirstSelectCompleted] = useState(false);
  const [secondSelectCompleted, setSecondSelectCompleted] = useState(false);

  // Pickers
  const [pickerOneTraitSelected, setPickerOneTraitSelected] = useState(null);
  const [pickerTwoTraitSelected, setPickerTwoTraitSelected] = useState(null);
  const [pickerThreeTraitSelected, setPickerThreeTraitSelected] =
    useState(null);

  // Pickers Percentages
  const [pickerOnePercentSelected, setPickerOnePercentSelected] =
    useState(null);
  const [pickerTwoPercentSelected, setPickerTwoPercentSelected] =
    useState(null);
  const [pickerThreePercentSelected, setPickerThreePercentSelected] =
    useState(null);

  const [percentageArray, setpercentageArray] = useState([
    "Top 10%",
    "Top 20%",
    "Top 30%",
    "Top 40%",
    "Bottom 10%",
    "Bottom 20%",
    "Bottom 30%",
    "Bottom 40%",
  ]);

  useEffect(() => {
    setError(null);

    async function getTraits() {
      try {
        const response = await apiCalls.getTraitsByCrop(cropData.id);
        if (response.data.length !== 0) {
          const data = response.data;
          setTraits(data);
        } else {
          setError("No traits data available.");
        }
      } catch (error) {
        console.log("Error fetching traits data:", error);
        setError("Error fetching traits data.");
      }
    }
    getTraits();
  }, []);

  useEffect(() => {
    setError(null);

    if (pickerOneTraitSelected && pickerOnePercentSelected) {
      async function getFirstPickerPhenotypes() {
        try {
          const topParam = {
            trait: pickerOneTraitSelected.value,
            top: `top=top:${pickerOnePercentSelected.percentValue}`,
          };

          const bottomParam = {
            trait: pickerOneTraitSelected.value,
            bottom: `bottom=bottom:${pickerOnePercentSelected.percentValue}`,
          };

          const ternaryParam =
            pickerOnePercentSelected.percentLabel == "Top"
              ? topParam
              : bottomParam;

          const queryParam = {
            ternaryParam,
          };

          const response = await apiCalls.getRequest.get(
            `/api/crops/${cropData.id}/filterphenotype/`,
            queryParam.ternaryParam
          );

          setFirstPickerPhenotypes(response);
          setFirstSelectCompleted(true);
          // setFilteredPhenotypes(response);
        } catch (error) {
          console.log("error getting phenotypes", error);
        }
      }
      getFirstPickerPhenotypes();
    }
  }, [pickerOneTraitSelected, pickerOnePercentSelected]);

  useEffect(() => {
    if (pickerTwoTraitSelected && pickerTwoPercentSelected) {
      async function getSecondPickerPhenotypes() {
        try {
          const topParam = {
            trait: pickerTwoTraitSelected.value,
            top: `top=top:${pickerTwoPercentSelected.percentValue}`,
          };

          const bottomParam = {
            trait: pickerTwoTraitSelected.value,
            bottom: `bottom=bottom:${pickerTwoPercentSelected.percentValue}`,
          };

          const ternaryParam =
            pickerTwoPercentSelected.percentLabel == "Top"
              ? topParam
              : bottomParam;

          const queryParam = {
            ternaryParam,
          };

          const response = await apiCalls.getRequest.get(
            `/api/crops/${cropData.id}/filterphenotype/`,
            queryParam.ternaryParam
          );

          setSecondPickerPhenotypes(response);
          setSecondSelectCompleted(true);
        } catch (error) {
          console.log("error getting phenotypes", error);
        }
      }
      console.log(firstPickerPhenotypes, secondPickerPhenotypes);
      getSecondPickerPhenotypes();
    }
  }, [pickerTwoTraitSelected, pickerTwoPercentSelected]);

  useEffect(() => {
    if (pickerThreeTraitSelected && pickerThreePercentSelected) {
      async function getThirdPickerPhenotypes() {
        try {
          const topParam = {
            trait: pickerThreeTraitSelected.value,
            top: `top=top:${pickerThreePercentSelected.percentValue}`,
          };

          const bottomParam = {
            trait: pickerThreeTraitSelected.value,
            bottom: `bottom=bottom:${pickerThreePercentSelected.percentValue}`,
          };

          const ternaryParam =
            pickerThreePercentSelected.percentLabel == "Top"
              ? topParam
              : bottomParam;

          const queryParam = {
            ternaryParam,
          };

          const response = await apiCalls.getRequest.get(
            `/api/crops/${cropData.id}/filterphenotype/`,
            queryParam.ternaryParam
          );

          setThirdPickerPhenotypes(response);
        } catch (error) {
          console.log("error getting phenotypes", error);
        }
      }
      getThirdPickerPhenotypes();
    }
  }, [pickerThreeTraitSelected, pickerThreePercentSelected]);

  console.log("FirstPicker:", firstPickerPhenotypes);
  console.log("SecondPicker:", secondPickerPhenotypes);
  console.log("ThirdPicker:", thirdPickerPhenotypes);

  const filteredPhenotypes = [
    ...firstPickerPhenotypes,
    ...(firstPickerPhenotypes.length !== 0 ? secondPickerPhenotypes : []),
    ...(secondPickerPhenotypes.length !== 0 ? thirdPickerPhenotypes : []),
  ];

  console.log("filteredPhenotypes", filteredPhenotypes);

  const options = percentageArray.map((percentage) => ({
    value: percentage,
    label: percentage,
  }));

  const handleChangeForPickerOne = (selectedOption) => {
    if (selectedOption) {
      // Split the selected option to get (example:"Top" and "20%")
      const [prefix, suffix] = selectedOption.value.split(" ");
      // Remove the "%" from the suffix
      const percentStr = suffix.replace("%", "");
      console.log(prefix);
      console.log(percentStr);
      // Update the selected option
      setPickerOnePercentSelected({
        percentLabel: prefix,
        percentValue: percentStr,
        label: selectedOption.label,
      });
    } else {
      setPickerOnePercentSelected(null);
    }
  };

  const handleChangeForPickerTwo = (selectedOption) => {
    if (selectedOption) {
      // Split the selected option to get (example:"Top" and "20")
      const [prefix, suffix] = selectedOption.value.split(" ");
      // Remove the "%" from the suffix
      const percentStr = suffix.replace("%", "");
      // Update the selected option
      setPickerTwoPercentSelected({
        percentLabel: prefix,
        percentValue: percentStr,
        label: selectedOption.label,
      });
    } else {
      setPickerTwoPercentSelected(null);
    }
  };

  const handleChangeForPickerThree = (selectedOption) => {
    if (selectedOption) {
      // Split the selected option to get (example:"Top" and "20")
      const [prefix, suffix] = selectedOption.value.split(" ");
      // Remove the "%" from the suffix
      const percentStr = suffix.replace("%", "");
      // Update the selected option
      setPickerThreePercentSelected({
        percentLabel: prefix,
        percentValue: percentStr,
        label: selectedOption.label,
      });
    } else {
      setPickerThreePercentSelected(null);
    }
  };

  return (
    <div>
      <div className="lineselection__header">
        <h1>{cropData.name} Line Selection</h1>
        <Link
          to="/filteredphenotypes"
          state={{ filteredPhenotypes, cropData }}
          className="lineselection__header__button">
          <span>Continue with {filteredPhenotypes.length} lines</span>
        </Link>
      </div>
      {error && (
        <Alert style={{ marginTop: "20px" }} variant="danger">
          {error}
        </Alert>
      )}

      <Row xs={1} lg={2} style={{ marginTop: "40px" }} className="g-4">
        <Col>
          <Select
            options={traits.map((trait) => ({
              value: trait.id,
              label: trait.name,
            }))}
            value={pickerOneTraitSelected}
            onChange={(selectedOption) =>
              setPickerOneTraitSelected(selectedOption)
            }
            placeholder="Select a Trait"
          />
        </Col>

        <Col>
          <Select
            value={pickerOnePercentSelected}
            onChange={handleChangeForPickerOne}
            options={options}
            placeholder="Select a Percentage"
          />
        </Col>
      </Row>

      <Row xs={1} lg={2} style={{ marginTop: "40px" }} className="g-4">
        <Col>
          <Select
            options={traits.map((trait) => ({
              value: trait.id,
              label: trait.name,
            }))}
            value={pickerTwoTraitSelected}
            onChange={(selectedOption) =>
              setPickerTwoTraitSelected(selectedOption)
            }
            placeholder="Select a Trait"
            isDisabled={!firstSelectCompleted}
          />
        </Col>

        <Col>
          <Select
            value={pickerTwoPercentSelected}
            onChange={handleChangeForPickerTwo}
            options={options}
            placeholder="Select a Percentage"
            isDisabled={!firstSelectCompleted}
          />
        </Col>
      </Row>

      <Row xs={1} lg={2} style={{ marginTop: "40px" }} className="g-4">
        <Col>
          <Select
            options={traits.map((trait) => ({
              value: trait.id,
              label: trait.name,
            }))}
            value={pickerThreeTraitSelected}
            onChange={(selectedOption) =>
              setPickerThreeTraitSelected(selectedOption)
            }
            placeholder="Select a Trait"
            isDisabled={!secondSelectCompleted}
          />
        </Col>

        <Col>
          <Select
            value={pickerThreePercentSelected}
            onChange={handleChangeForPickerThree}
            options={options}
            placeholder="Select a Percentage"
            isDisabled={!secondSelectCompleted}
          />
        </Col>
      </Row>
    </div>
  );
}

export default LineSelection;

// useEffect(() => {
//   setError(null);

//   if (pickerOneTraitSelected && pickerOnePercentSelected) {
//     async function getPhenotypes() {
//       try {
//         const topParam = {
//           trait: pickerOneTraitSelected.value,
//           top: `top=top:${pickerOnePercentSelected.percentValue}`,
//         };

//         const bottomParam = {
//           trait: pickerOneTraitSelected.value,
//           bottom: `bottom=bottom:${pickerOnePercentSelected.percentValue}`,
//         };

//         const ternaryParam =
//           pickerOnePercentSelected.percentLabel == "Top"
//             ? topParam
//             : bottomParam;

//         const queryParam = {
//           ternaryParam,
//         };

//         console.log(queryParam);

//         const response = await apiCalls.getRequest.get(
//           `/api/crops/${cropData.id}/filterphenotype/`,
//           queryParam.ternaryParam
//         );

//         setFirstPickerPhenotypes(response);
//       } catch (error) {
//         console.log("error getting phenotypes", error);
//       }
//     }
//     getPhenotypes();
//   }

//   if (pickerTwoTraitSelected && pickerTwoPercentSelected) {
//     async function getSecondPickerPhenotypes() {
//       try {
//         const topParam = {
//           trait: pickerTwoTraitSelected.value,
//           top: `top=top:${pickerTwoPercentSelected.percentValue}`,
//         };

//         const bottomParam = {
//           trait: pickerTwoTraitSelected.value,
//           bottom: `bottom=bottom:${pickerTwoPercentSelected.percentValue}`,
//         };

//         const ternaryParam =
//           pickerTwoPercentSelected.percentLabel == "Top"
//             ? topParam
//             : bottomParam;

//         const queryParam = {
//           ternaryParam,
//         };

//         console.log(queryParam);

//         const response = await apiCalls.getRequest.get(
//           `/api/crops/${cropData.id}/filterphenotype/`,
//           queryParam.ternaryParam
//         );

//         setSecondPickerPhenotypes(response);
//       } catch (error) {
//         console.log("error getting phenotypes", error);
//       }
//     }
//     getSecondPickerPhenotypes();
//   }
// }, [
//   pickerOneTraitSelected,
//   pickerOnePercentSelected,
//   pickerTwoTraitSelected,
//   pickerTwoPercentSelected,
// ]);
