import React, { useEffect, useState } from "react";
import FilterComponent from "../../components/ui/FilterComponent";
import plantImg from "../../assets/plantImg.png";
import FilterModal from "../../components/ui/FilterModal";
import apiCalls from "../../services/apiCalls";
import { useParams, useLocation, Link } from "react-router-dom";

//Images
import noLineImage from "../../assets/no_image_available.jpeg";

//Bootstrap / Icons
import { Card, Col, Row, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

// CSS
import "./FindALine.css";

function FindALine(props) {
  // const { data } = useParams();
  const location = useLocation();
  const cropData = location.state.crop;

  const [show, setShow] = useState(false);
  const [phenotypes, setPhenotypes] = useState([]);
  const [lines, setLines] = useState([]);
  const [filteredResults, setFilteredResults] = useState(lines);
  const [ranks, setRanks] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSearch = (e) => {
    let value = e.target.value;
    let result = [];

    result = lines.filter((data) => {
      return data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    });

    setFilteredResults(result);
  };

  useEffect(() => {
    apiCalls
      .getLines(cropData.id)
      .then((res) => {
        console.log(res.data);
        setLines(res.data);
        setFilteredResults(res.data);
      })
      .catch((error) => {
        console.log("error not getting data: " + error);
      });
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await apiCalls.getPhenoTypesByCrop(cropData.id);
        const responseData = response.data;
        console.log(responseData);
        setPhenotypes(responseData);
        sortAndRankPhenotypes(responseData);
      } catch (error) {
        console(error);
      }
    }
    fetchData();
  }, []);

  const sortAndRankPhenotypes = async (data) => {
    // console.log(data);
    let sortedPheno = data
      .slice()
      .sort((a, b) => ((a.value_num ?? 0.0) > (b.value_num ?? 0.0) ? -1 : 1));

    console.log(sortedPheno);

    let dictionary = sortedPheno.reduce((acc, element) => {
      acc[element.trait] = acc[element.trait] || [];
      acc[element.trait].push(element);
      return acc;
    }, {});
    console.log(dictionary);

    // Ranking Dictionary
    let index = 0;
    let prev = null;
    let currentRank = 0;
    let newRanks = new Set(ranks);
    // let setRanks = []

    for (let key in dictionary) {
      if (dictionary.hasOwnProperty(key)) {
        index = 0;
        dictionary[key].forEach((item) => {
          index += 1;
          if (prev === null || item.line !== prev) {
            prev = item.line;
            currentRank = index;
          }
          newRanks.add({
            rank: currentRank,
            lineValue: item.value_num ?? 0.0,
            lineName: item.line,
            traitName: item.trait,
          });

          // setRanks.push({
          //   rank: currentRank,
          //   lineValue: item.value_num ?? 0.0,
          //   lineName: item.line,
          //   traitName: item.trait,
          // });
        });
      }
    }

    setRanks(Array.from(newRanks));

    // console.log("DictionarysetRanks", ranks);
  };

  console.log(ranks);

  return (
    // Set to Horizontal
    <div className="findALine__container">
      {/* Filter Component */}
      {/* <FilterComponent /> */}
      {/* Lines Component */}
      <div className="findALine__data">
        <h1>{cropData.name} Find A Line</h1>
        {/* Show this when screen is on phone/Ipad  */}
        <div className="findALine__smallscreen__filter">
          <div className="findALine__smallscreen__filter_context">
            <FontAwesomeIcon icon={faFilter} onClick={handleShow} />
            <span onClick={handleShow}>Filters </span>
            <Modal show={show} onHide={handleClose} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Modal heading</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Woohoo, you are reading this text in a modal!
              </Modal.Body>
            </Modal>
          </div>
        </div>

        {/* Search Bar */}
        <form class="search-form">
          <FontAwesomeIcon icon={faMagnifyingGlass} />
          <input
            type="text"
            placeholder="Search"
            class="search-input"
            onChange={(e) => handleSearch(e)}
          />
        </form>

        <Row xs={1} md={2} lg={3} className="g-4">
          {filteredResults.length !== 0 ? (
            filteredResults.map((line) => (
              <Col key={line.id}>
                <Link to="/linedetail" state={{ line, ranks }}>
                  <Card className="findALine__card">
                    <Card.Img
                      variant="top"
                      src={
                        line.picture !== null
                          ? apiCalls.baseURL + line.picture
                          : noLineImage
                      }
                      style={{ height: "400px" }}
                    />
                    <Card.Body>
                      <Card.Title
                        style={{ fontWeight: "bold", fontSize: "22px" }}>
                        {line.name}
                      </Card.Title>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            ))
          ) : (
            <h2> No Lines Found</h2>
          )}
        </Row>
      </div>
    </div>
  );
}

export default FindALine;
