import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useLocation } from "react-router-dom";
import apiCalls from "../../services/apiCalls";

// Images
import noImageFound from "../../assets/no_image_available.jpeg";

// Bootstrap / Icons
import { Row, Col } from "react-bootstrap";

// CSS
import "./LineDetails.css";

const LineDetails = () => {
  const location = useLocation();
  const line = location.state.line;
  const ranks = location.state.ranks;
  const [activeIndex, setActiveIndex] = useState(0);
  const [photoLines, setPhotoLines] = useState([]);
  const [subImageUrls, setSubImageUrls] = useState([]);
  const [attributeArray, setAttributeArray] = useState([]);
  console.log(ranks);

  useEffect(() => {
    apiCalls
      .getLinePhotos(line.id)
      .then((res) => {
        const baseURL = apiCalls.baseURL;

        setPhotoLines(res.data);

        // Use a Set to avoid duplicates
        const uniqueUrls = new Set(subImageUrls);

        for (let i in res.data) {
          const result = baseURL.concat("", res.data[i].picture);
          uniqueUrls.add(result);
        }

        // Convert the Set back to an array and update state
        setSubImageUrls(Array.from(uniqueUrls));
      })
      .catch((error) => {
        console.log("Error getting line images ", error);
      });
  }, []);

  useEffect(() => {
    setRank();
  }, []);

  const setRank = async () => {
    let filterData = [];
    let filterPhenotype = ranks.filter((item) => item.lineName === line.id);
    console.log("filterPhenotype", filterPhenotype);

    for (let i in filterPhenotype) {
      const response = await apiCalls.getTraits();
      let data = response.data;
      let filter = data.filter(
        (item) => item.id === filterPhenotype[i].traitName
      );
      console.log(filter);

      for (let j in filter) {
        filterData.push({
          traitName: filter[j].name,
          numberOfLines: 40,
          target: filter[j].target,
          target_direction: filter[j].target_direction,
          rank: filterPhenotype[i].rank,
        });
      }
    }
    console.log("filterData", filterData);
    sortRank(filterData);
  };

  const sortRank = (data) => {
    let transformedArray = data
      .sort((a, b) => a.rank - b.rank)
      .map((trait) => {
        let numberOfLines = Math.round(trait.numberOfLines);
        let tNorm = ((numberOfLines - trait.rank) / numberOfLines) * 100;
        let tNormConverted = Math.round(tNorm);
        let convertedValue = tNormConverted / 100.0;

        return {
          traitName: trait.traitName,
          numberOfLines: trait.numberOfLines,
          rank: trait.rank,
          tNormValue: parseInt(tNormConverted),
          convertedValue: convertedValue,
          target: trait.target,
          target_direction: trait.target_direction,
        };
      });

    setAttributeArray(transformedArray);
  };

  console.log(attributeArray);

  const producerCharts = attributeArray.map((item, index) => {
    console.log(item.tNormValue);
    let redColor = item.tNormValue <= 33 ? "#FF0000" : "";
    let yellowColor =
      item.tNormValue >= 33 && item.tNormValue <= 66 ? "#FFFF00" : "";
    let greenColor =
      item.tNormValue >= 66 && item.tNormValue <= 100 ? "#008000" : "";

    let setColor =
      item.tNormValue <= 30
        ? redColor
        : item.tNormValue >= 33 && item.tNormValue <= 66
        ? yellowColor
        : item.tNormValue >= 66 && item.tNormValue <= 100
        ? greenColor
        : "";

    let options = {
      series: [item.tNormValue],
      chart: {
        type: "radialBar",
        height: 200,
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "90%",
            margin: 5,
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: "#999",
              opacity: 1,
              blur: 1,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: "18px",
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91],
          colors: ["#fff"],
        },
      },
      labels: [item.traitName],
      colors: [setColor],
    };

    return (
      <>
        {item.target === "prod" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <h5 style={{ fontSize: "19px" }}> {item.traitName}</h5>
            <Chart
              key={index}
              options={options}
              series={options.series}
              type="radialBar"
              height={150}
            />
          </div>
        )}
      </>
    );
  });

  const consumerCharts = attributeArray.map((item, index) => {
    console.log(item.tNormValue);
    let redColor = item.tNormValue <= 33 ? "#FF0000" : "";
    let yellowColor =
      item.tNormValue >= 33 && item.tNormValue <= 66 ? "#FFFF00" : "";
    let greenColor =
      item.tNormValue >= 66 && item.tNormValue <= 100 ? "#008000" : "";

    let setColor =
      item.tNormValue <= 30
        ? redColor
        : item.tNormValue >= 33 && item.tNormValue <= 66
        ? yellowColor
        : item.tNormValue >= 66 && item.tNormValue <= 100
        ? greenColor
        : "";

    let options = {
      series: [item.tNormValue],
      chart: {
        type: "radialBar",
        height: 200,
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "90%",
            margin: 5,
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: "#999",
              opacity: 1,
              blur: 1,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: "18px",
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91],
          colors: ["#fff"],
        },
      },
      labels: [item.traitName],
      colors: [setColor],
    };

    return (
      <>
        {item.target === "cons" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <h5 style={{ fontSize: "19px" }}> {item.traitName}</h5>
            <Chart
              key={index}
              options={options}
              series={options.series}
              type="radialBar"
              height={150}
            />
          </div>
        )}
      </>
    );
  });

  const handleSubImageClick = (index) => {
    setActiveIndex(index);
  };

  // Ensuring subImageUrls is an array before calling map()
  const lineImages = subImageUrls.length != 0 ? subImageUrls : [noImageFound];

  return (
    <div className="linedetails__container">
      <div className="linedetails__leftside_context">
        <img src={lineImages[activeIndex]} alt="lineimage" />
        <div className="linedetail__leftside__subimages">
          {lineImages.map((url, index) => (
            <img
              // className="linedetail__img"
              key={index}
              src={url}
              alt={`Sub Image ${index + 1}`}
              onClick={() => handleSubImageClick(index)}
              // className={index === activeIndex ? "linedetail__img" : ""}
              style={{
                width: "190px",
                height: "120px",
                cursor: "pointer",
                marginTop: "10px",
                marginLeft: "10px",
              }}
            />
          ))}
        </div>
      </div>

      <div className="linedetails__rightside_context">
        <h3>{line.name}</h3>
        <span>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged
        </span>

        <div className="linedetail__rightside__attributes__context">
          <h4 style={{ marginBottom: "30px" }}>Producer Attribute</h4>

          <Row xs={3} md={3} lg={4} className="g-4">
            {producerCharts}
          </Row>
          <h4 style={{ marginTop: "30px", marginBottom: "30px" }}>
            Consumer Attribute
          </h4>
          <Row xs={3} md={3} lg={4} className="g-4">
            {consumerCharts}
          </Row>
        </div>
        <button className="linedetail__button">Add to List</button>
      </div>
    </div>
  );
};

export default LineDetails;
