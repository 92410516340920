import React, { useEffect, useState } from "react";
import apiCalls from "../../services/apiCalls";
import TraitChartModal from "../../components/ui/TraitChartModal";
import { Link, useLocation } from "react-router-dom";

// Bootstrap
import { Row, Col, Alert } from "react-bootstrap";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSeedling } from "@fortawesome/free-solid-svg-icons";

// CSS
import "./Traits.css";

function Traits(props) {
  const location = useLocation();
  const cropData = location.state.crop;
  const [traits, setTraits] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState(null);
  const togglePopup = () => setIsOpen(!isOpen);

  console.log(isOpen);
  useEffect(() => {
    setError(null);
    async function getTraits() {
      try {
        const response = await apiCalls.getTraitsByCrop(cropData.id);

        if (response.data.length !== 0) {
          setTraits(response.data);
        } else {
          setError("No traits found.");
        }
      } catch (error) {
        setError("Failed to get trait request");
      }
    }
    getTraits();
  }, []);

  return (
    <div>
      <h2> Lettuce Traits View </h2>
      {error && (
        <Alert style={{ marginTop: "20px" }} variant="danger">
          {error}
        </Alert>
      )}
      {/* Modal Pop up */}
      {/* <div>
        <TraitChartModal />
      </div> */}
      {/* {isOpen && <TraitChartModal />} */}

      {/* Line Selection Traits */}
      {traits.length !== 0 && (
        <div
          style={{
            marginTop: "40px",
            height: "50vh",
            overflowX: "scroll",
            padding: "10px",
          }}>
          <h4> Line Selection Traits</h4>
          <Row xs={1} md={2} lg={4} className="g-4">
            {traits.map((trait) => (
              <div class="card-list" onClick={togglePopup}>
                <Link to={`/traitchart/${trait.id}`}>
                  <article class="card__article">
                    <figure class="card-image">
                      <FontAwesomeIcon icon={faSeedling} size="4x" />
                    </figure>
                    <div class="card-header">
                      <span>{trait.name}</span>
                    </div>
                  </article>
                </Link>
              </div>
            ))}
          </Row>
        </div>
      )}

      {/* Build A Line Traits */}

      {/* <div
        style={{
          marginTop: "40px",
          height: "50vh",
          overflowX: "scroll",
          padding: "10px",
        }}>
        <h4> Build A Line Traits</h4>
        <Row xs={1} md={2} lg={4} className="g-4">
          {traits.map((trait) => (
            <div class="card-list">
              <article class="card">
                <figure class="card-image">
                  <FontAwesomeIcon icon={faSeedling} size="4x" />
                </figure>
                <div class="card-header">
                  <span>{trait.name}</span>
                </div>
              </article>
            </div>
          ))}
        </Row>
      </div> */}
    </div>
  );
}

export default Traits;
