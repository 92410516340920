import { createStore, combineReducers, compose, applyMiddleware } from "redux"
import {thunk} from "redux-thunk"
// Reducers
import { authReducer } from "./reducers/auth"


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const middleware = [thunk];


const reducer = combineReducers({
    auth: authReducer,
})

const store = createStore(
    reducer,
    // applyMiddleware(...middleware)
    composeEnhancers(applyMiddleware(thunk))
)

export default store;