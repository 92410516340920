import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import apiCalls from "../../services/apiCalls";
import { useParams, useNavigate } from "react-router-dom";

// Icons

// Bootstrap

// CSS
import "./ui.css";

function TraitChartModal(props) {
  const { id } = useParams();
  const navigate = useNavigate();

  const [trait, setTrait] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  // const togglePopup = () => setIsOpen(!isOpen);

  const closePopup = () => {
    setIsOpen(false);
    // Optionally, you can navigate back to the previous page or a specific route
    navigate(-1);
  };

  useEffect(() => {
    apiCalls
      .getTrait(id)
      .then((res) => {
        setTrait(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log("Error loading Trait ", err);
      });
  }, []);

  // Example => y: [min, q1, median, q3, max] }]
  const options = {
    chart: {
      type: "boxPlot",
    },
    title: {
      text: "",
    },
    plotOptions: {
      boxPlot: {
        colors: {
          upper: "#4CAF50",
          lower: "#FFC107",
        },
      },
    },
    xaxis: {
      categories: ["Category 1"],
    },
    yaxis: {
      title: {
        text: "Values",
      },
    },
  };

  const series = [
    {
      name: "Series 1",
      data: [
        {
          x: "Trait",
          y: [10, 20, 25, 30, 45],
        },
      ],
    },
  ];
  return (
    <>
      <div className="popup-container">
        {/* <div className={`popup-container ${isOpen ? "open" : ""}`}> */}
        {/* <button onClick={togglePopup}>Open Popup</button> */}
        {/* {isOpen && ( */}
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <div className="popup-header-context">
              <h3>{trait.name}</h3>
              <span onClick={closePopup}>X</span>
            </div>

            <Chart
              options={options}
              series={series}
              type={options.chart.type}
              width="800"
              height="500"
            />

            {trait.description && (
              <div className="popup-description">
                <h3>Description</h3>
                <span>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum. Lorem
                  Ipsum is simply dummy text of the printing and typesetting
                  industry.
                </span>
              </div>
            )}
          </div>
        </div>
        {/* )} */}
      </div>
    </>
  );
}

export default TraitChartModal;
