import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { AuthContext } from "../Services/AuthContext";
// import logo from "../assets/BLOCK-logos_black.png";
import CustomModal from "../components/ui/CustomModal";
import { logoutUser } from "../redux/actions/auth";

// Bootstrap / Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import { Navbar, Button, Nav, NavDropdown } from "react-bootstrap";

// CSS

function NavBar(props) {
  //   const authContext = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const handleLogout = () => {
    // Call the logout function from AuthContext
    // authContext.logout();
    dispatch(logoutUser());
  };
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Navbar
        bg="light"
        className="navbar shadow p-3 mb-5 bg-white rounded"
        expand>
        {/* variant="outline-success" */}
        <Button
          style={{
            color: "white",
            backgroundColor: "black",
            border: "0px",
            borderWidth: 0,
          }}
          onClick={props.toggle}>
          <FontAwesomeIcon icon={faAlignLeft} />
          {/* <img
          src={logo}
          alt="Toggle Button"
          className="image-class"
        /> */}
        </Button>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end">
          <Nav className="ml-auto" navbar>
            {/* <Nav.Link href="#">page</Nav.Link>
            <Nav.Link href="#">page</Nav.Link>
            <Nav.Link href="#">page</Nav.Link> */}
            <Nav.Link
              onClick={handleLogout}
              style={{ color: "black", fontWeight: "bold" }}>
              Logout
            </Nav.Link>{" "}
            {/* Call handleLogout on logout click */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <CustomModal
        show={showModal}
        onHide={handleCloseModal}
        title="Sample Modal"
        content="This is the modal content."
      />
    </>
  );
}

export default NavBar;
