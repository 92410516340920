// Login constants
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_ERROR = "LOGIN_ERROR";

// Logout constanst
export const LOGOUT = "LOGIN_SUCCESS";
// export const LOGOUT_PENDING = "LOGIN_PENDING"
// export const LOGOUT_ERROR = "LOGIN_ERROR"

// Refresh constants
export const REFRESH_TOKEN = "REFRESH_TOKEN";

// Message constants
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

//
export const SET_CURRENT_USER = "SET_CURRENT_USER";

// Crops constants
export const CROPS_SUCCESS = "CROP_SUCCESS";
export const CROPS_PENDING = "CROPS_PENDING";
export const CROPS_ERROR = "CROPS_ERROR";

// Lines constants
export const LINES_SUCCESS = "LINES_SUCCESS";
export const LINES_PENDING = "LINES_PENDING";
export const LINES_ERROR = "LINES_ERROR";

// Phenotypes constants
export const PHENOTYPES_SUCCESS = "PHENOTYPES_SUCCESS";
export const PHENOTYPES_PENDING = "PHENOTYPES_PENDING";
export const PHENOTYPES_ERROR = "PHENOTYPES_ERROR";

// Traits constants
export const TRAITS_SUCCESS = "TRAITS_SUCCESS";
export const TRAITS_PENDING = "TRAITS_PENDING";
export const TRAITS_ERROR = "TRAITS_ERROR";

// LineNotes constants
export const LINE_NOTES_SUCCESS = "LINE_NOTES_SUCCESS";
export const LINE_NOTES_PENDING = "LINE_NOTES_PENDING";
export const LINE_NOTES_ERROR = "LINE_NOTES_ERROR";
