import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  REFRESH_TOKEN,
  SET_MESSAGE,
  SET_CURRENT_USER,
} from "../constans/authConstants";
import reAuthenticateURL from "../../services/apiCalls";
import apiCall from "../../services/apiCalls";
import setAuthToken from "../../services/setAuthToken";

export const loginUser = (userData) => (dispatch) => {
  // reAuthenticateURL
  apiCall.reAuthenticateURLInstance
    .post("api/token/", userData)
    .then((response) => {
      // Set token to localStorage
      const token = response.data;
      console.log(token);
      localStorage.setItem("jwtToken", JSON.stringify(token));

      //Set Token to Auth header
      setAuthToken(token);

      // Set current user
      dispatch(setCurrentUser(token));
    })
    .catch((error) => {
      console.log("error", error);
      dispatch({
        type: LOGIN_ERROR,
        payload: error.response.data,
      });
    });
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};

export const refreshToken = (accessToken) => (dispatch) => {
  dispatch({
    type: REFRESH_TOKEN,
    payload: accessToken,
  });
};
